// AnimatedTitle.js

import { useEffect } from "react";
import { useState } from 'react';

import styled from "styled-components";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Title = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  font-family: "Anderson Grotesk Light", sans-serif;
  letter-spacing: 1.5px;
`;

const Word = styled(motion.span)`
  display: inline-block;
  margin-right: 0.25em;
  white-space: nowrap;
`;

const Character = styled(motion.span)`
  display: inline-block;
  margin-right: -0.05em;
`;



export default function AnimatedTitle() {
  const [isCopied, setIsCopied] = useState(false);

  const intro = 'Hi there, I\'m Lauren' // This would normally be passed into this component as a prop!
  const about = 'Proficient in both design and development, I blend creativity into my work, always prioritizing people, research, and collaboration.' // This would normally be passed into this component as a prop!

  const ctrls = useAnimation();

  const handleClick = (event) => {
    event.preventDefault();
    navigator.clipboard.writeText('lauren.parkos@gmail.com');
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000); // hide tooltip after 2 seconds
  };
  
  const { ref, inView } = useInView({
    threshold: 0.9,
    triggerOnce: true,
  });
  
  useEffect(() => {
    if (inView) {
      ctrls.start("visible");
    }
    if (!inView) {
      ctrls.start("hidden");
    }
  }, [ctrls, inView]);
  
  const wordAnimation = {
    hidden: {},
    visible: {},
  };
  
  const characterAnimation = {
    hidden: {
      opacity: 0,
      y: `0.5em`,
    },
    visible: {
      opacity: 1,
      y: `0em`,
      transition: {
        duration: 0.01,
        ease: [0.2, .1, 0.3, 0.9],
      },
    },
  };
  
  return (
    <div className='d-block mx-5 col-lg-5 pt-5'>
    <Title aria-label={intro} role="heading">
      {intro.split(" ").map((word, index) => {
        return (
          <Word
            ref={ref}
            aria-hidden="true"
            key={index}
            initial="hidden"
            animate={ctrls}
            variants={wordAnimation}
            transition={{
              delayChildren: index * 0.05,
              staggerChildren: 0.05,
            }}
          >
            {word.split("").map((character, index) => {
              return (
                <Character
                  aria-hidden="true"
                  key={index}
                  variants={characterAnimation}
                >
                  {character}
                </Character>
              );
            })}
          </Word>
        );
      })}
    </Title>

    <Title aria-label={about} role="heading">
      {about.split(" ").map((word, index) => {
        return (
          <Word
            ref={ref}
            aria-hidden="true"
            key={index}
            initial="hidden"
            animate={ctrls}
            variants={wordAnimation}
            transition={{
              delayChildren: index * 0.05,
              staggerChildren: 0.05,
            }}
          >
            {word.split("").map((character, index) => {
              return (
                <Character
                  aria-hidden="true"
                  key={index}
                  variants={characterAnimation}
                >
                  {character}
                </Character>
              );
            })}
          </Word>
        );
      })}
    </Title>

    <p className="fs-4">With 10 years working at the intersection of human-centered design and technology, I’ve been fortunate to work for clients like GE, Google, Apple, McDonalds, Goldman Sachs, Comcast, and many more.</p>

    <hr></hr>

    <div className="my-5">
    <p className="fs-5 bold">Want to chat?</p>
      <a className="fs-5 my-2 me-3" href="https://www.linkedin.com/in/laurenparkos/">LinkedIn</a><br></br>
      <a className="fs-5 my-2" onClick={handleClick}>lauren.parkos@gmail.com</a>
      {isCopied && <p className="text-body-secondary non-serif light">Copied to clipboard!</p>}
    </div>
    </div>
  );
}