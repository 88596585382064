import React, { useState, useEffect } from "react";
import getContent from '../components/getContent.js';
import ProjectHero from "../components/ProjectHero.js";
import ProjectSetup from "../components/ProjectSetup.js";
import Block from "../components/Block.js";
import NextBack from "../components/NextBack.js";
import Image from "../components/Image.js";
import InsightsBlock from "../components/InsightsBlock.js";
import DetailLayout from "../components/Detaillayout.js";
import SectionTitle from "../components/SectionTitle.js";

import personas from "../images/assets/voiceux/personas.png";
import opportunites from "../images/assets/voiceux/opportunities.png";
import workflow2 from "../images/assets/voiceux/workflow-2.png";
import floorplans from "../images/assets/voiceux/floor-plans.png";
import response from "../images/assets/voiceux/response.png";
import utterance from "../images/assets/voiceux/utterance.png";
import usecases from "../images/assets/voiceux/usecases.png";
import hero from "../images/assets/voiceux/hero.png";
import types from "../images/assets/voiceux/types.png";

import press from "../images/assets/voiceux/press.png";







const VoiceUX = () => {
  return (
    <DetailLayout>
       <div className="body-container">

       <ProjectHero
       client="GE Healthcare"
        project="Finding value in voice user interfaces for radiology technicians"
        summary="While at GE, I worked with Radiology Technicians and other stakeholders to solve customer pain points, design and user test a voice user interface that would allow technicians to control scanner modalities with voice commands. "
        image={hero} 
        />

      <SectionTitle title="Project Snapshot" />


        <ProjectSetup
        solution="Learn common work patterns for radiology technicians working in 3 areas, understand user objectives and map out common activities to natural language. "
        outcome="Validate if users are keen to connected modalities, define user groups and value opportunities. "
        timeline="3 Months"
        problem="Radiology technicians spend a lot of time and exert physical labor adjusting, setting up and programming scanner modalities. "
        />

        <SectionTitle title="Process" />
        <Block subhead="Approach" subtext="During a research trip we learned about our users by job shadowing them and learning about their daily job functions. We paid close attention to how users were communicating with their patients and what common phrases were frequently used. After our research we assembled key user personas, and gathered common use cases to understand the nature of each job function. " />

        <Image image={personas} />

        <Block subhead="Identifying opportunites" subtext="After learning about our users, I logged use cases, and then analyzed user walking patterns. The walking patterns were interesting because the area covered by each individual wasn't as spacially straight forward, thus pointing out difficulties in where to put command microphones. " />

        <Image image={opportunites} />

        <Block subhead="Finding value" subtext="After gathering my research and conducting interviews, I created a value proposition map that highlighted areas of significant improvement for technician workflows. " />

        <Image image={floorplans} />

        <Block subhead="Desiging a common utterance" subtext="I focussed on creating a chart for the disease stage. This was the chart that needed to allow for muliple Y-Axis, and started to come up with 3 layout options, as well as experiment with different types of scalers to zoom in and out of data." />
        <Image image={utterance} />

        <Block subhead="Designing a response" subtext="When our opportunities were defined, I set out to create a simple voice pattern that encorporated common phrases radiology technicians used. Engineering was already working with a 3rd party library that used its own NLP framework, however they had capability to customize certain parts. I worked with them to build a formula that was based on the language of our customers. "/>
        <Image image={response} />

        <Block subhead="Light & Sound" subtext="Next we sought out to create a command formula that could be used to structure voice commands. This formula was designed to match real user phrases that we collected during our interviews, and aimed to feel natural to command. "/>
        <Image image={types} />

        <Block subhead="Generating a Buzz" subtext="After we established a baseline, we created a prototype using the Alexa kit, and demo'd the pilot at the main RSNA tradeshow, which generated some buzz in the press."/>
        <Image image={press} />

      </div>
    </DetailLayout>
  )
}

export default VoiceUX;
