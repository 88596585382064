import React, { useState } from 'react';
import { Container, Row, Col, Navbar, Nav, Button } from 'react-bootstrap';

function About() {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className='d-block pe-4'>
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? (
        <div>
          <p className='h4 light'>Simply put,</p>
          <p className='h2 light'>I have experience in both design and development, and I like to test my work. And hey, I'm also creative and love a good laugh.</p>
          </div>
      ) : (
        <div>
          <p className='h4 light'>I'm Lauren,</p>
          <p className='h2 light'>I have experience in both design and development, and I like to test my work. And hey, I'm also creative and love a good laugh.</p>
          {/* <p className='h2 light'>A user-centric product designer, innovating with purpose across production, front-end engineering, and creative realms with research at my core.</p> */}
        </div>
      )}
    </div>
    <p className='h4 mt-5'>I'm currently working @VMWare on the Tanzu product line.</p>
    <p className='h4'>Find me on Linked.In</p>
    </div>
  );
}

export default About;