import React, {useEffect, useState} from 'react';
import { Container } from 'react-bootstrap';

class SectionTitle extends React.Component {
  render() {
    return <Container>
              <div className="pt-3 pb-2">
                <h5 className="uppercase text-body-tertiary light">{this.props.title}</h5>
              </div>
          </Container>
  }
}

export default SectionTitle;
