import React, { useState, useEffect } from "react";
import ProjectSetup from "../components/ProjectSetup.js";
import ProjectHero from "../components/ProjectHero.js";
import InsightsBlock from "../components/InsightsBlock.js";

import Block from "../components/Block.js";
import SectionTitle from "../components/SectionTitle.js";
import Image from "../components/Image.js";
import DetailLayout from "../components/Detaillayout";

import interviews from "../images/assets/ai/interviews.png";
import usecases from "../images/assets/ai/use-cases.png";
import docs from "../images/assets/ai/docs-concept.png";
import designercopilot from "../images/assets/ai/Design-Demo-Video-Draft.mp4";
import backstage from "../images/assets/ai/backstage-concept.png";
import { Container } from "react-bootstrap";


const AIcopilot = () => {
    return (
      <DetailLayout>
        <div className="body-container">
        
        <ProjectHero
        client="VMWare Tanzu"
        project="Innovating with LLM's to create a new intelligent chat plugin for app developers"
        summary={["Given the rapid growth of AI capabilities, and the transformative effect it has on the developer experience, a coworker and I embarked on a side project to thoroughly explore potential AI integration for our products. Our goal was to investigate potential uses, capabilities, and other friction points that we could potentially address with AI."]}
        timeline="Ongoing"
        />
        
        <SectionTitle title="Project Snapshot" />

        <ProjectSetup
          problem="How can we use AI to improve the developer experience?"
          solution="A series of prototypes that explore the use of AI in the developer experience, primarily through a chatbot."
          outcome="User research and prototypes that will be used to inform future product decisions."
          />

        <SectionTitle title="Process" />
        <Block subhead="Internal Research" subtext="To better frame our work, and learn more about the space, we began interviewing domain experts, and other people interested in potential of AI integration within our company. We were able to identify people from a few different domains which helped us gather a wide perspective on the space. We talked to developers, technical writers, and fellow designers to gather data on what problems might be best to solve with the help of AI. "  />

        <Image image={interviews} />

        <Block subhead="Finding Use Cases from Data" subtext="From our initial research, we were able to uncover use cases that we could potentially solve for, and leverage in our products. Once we laid out a few user journeys based on friction points we discovered during our research phase, we began concepting several prototypes to share with stakeholders throughout our organization."  />

        <Image image={usecases} />

        <Block subhead="Use Cases" subtext="We were able to uncover use cases that we could potentially solve for, and leverage in our products."  />

        <InsightsBlock 
            insights1="1. A chatbot to replace the need for platform operators to jump to documentation anytime they encountered a problem while managing our platform."
            insights2="2. A chatbot assistant to help users while reading through our platforms documentation."
            insights3="3. A developer co-pilot to auto-generate documentation whenever merging new code for our product. "
          />

        <Block subhead="Concept Generation" subtext="We generated concepts based off of our use cases and user research and prepared a pitch deck that caught the attention of some influential figures in our company, and we were delighted to get the chance to share our findings with them."  />

        <Image image={backstage} />
        <Image image={docs} />
        <Container><video width="98%" controls autoplay muted src={designercopilot} class="ps-4 pb-4 object-fit-lg-contain"></video></Container>
        

      </div>
    </DetailLayout>
 
    )
  }


export default AIcopilot;



