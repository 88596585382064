import React, {useEffect, useState} from 'react';
import { Container } from 'react-bootstrap';

class Block extends React.Component {
  render() {
    return <Container>
              <div className="py-3">
                <h1 className="fs-2 lh-base bold">{this.props.subhead}</h1>
                <p className="fs-4">{this.props.subheadhead}</p>
                <p className="fs-5">{this.props.subtext}</p>
              </div>
          </Container>
  }
}

export default Block;
