import React, {useEffect, useState} from 'react';
import { Container } from 'react-bootstrap';

const ProjectHero = (props) => {
  return (
    <Container className="mb-5 pb-5">
        <div class="row pb-5">
          <div class="col-lg-10 col-sm-12">
            <div className="container-fluid">
                <h5 className="light">{props.client}</h5>
            </div>
          </div>
          <div class="col-2"></div>
        </div>
        <div class="row">
          <div class="col-lg-10 col-sm-12">
            <div className="container-fluid">
                <h1 className="regular">{props.project}</h1>
            </div>
          </div>
          <div class="col-2"></div>
        </div>
        <div class="row">
            <div class="col-lg-10 col-sm-12">
              <div className="container-fluid">
                  <h4 className="light">{props.summary}</h4>
              </div>
            </div>
            <div class="col-2"></div>
        </div>
        

        {/* <div className="container-fluid d-flex">
          <div className="me-5">
            <h5 className="bold">Company</h5>
            <p className="text-body-secondary">{props.company}</p>
          </div>
          <div>
            <h5 className="bold">Timeline</h5>
            <p className="text-body-secondary">{props.timeline}</p>
          </div>
        </div> */}


        {/* <div className="container-fluid mb-5">
          <img className="w-100" src={props.image} />
        </div> */}
    </Container>
  );
};

export default ProjectHero;
