// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    /* CSS HEX */
    --stormfront: #3d5a80;
    --bluesky: #614f60;
    --clarity:  #ffffff;
    --mango: #ee6c4d;
    --moodswing: #000;
    --stone: #e9e3e3;
    --translucent: #ffffff4a;
    }
    
    
    /* COLORS */
    .stormfront {
        color: var(--stormfront);
    }
    
    .bluesky {
        color: var(--bluesky);
    }
    
    .clarity {
        color: var(--clarity);
    }
    
    .mango {
        color: var(--mango);
    }
    
    .moodswing {
        color: var(--moodswing);
    }

    .stone {
        color: var(--stone);
    }

    .translucent {
        color: var(--translucent);
    }

    /* BACKGROUND COLORS */
    .bg-stormfront {
        background-color: var(--stormfront);
    }
    
    .bg-bluesky {
        background-color: var(--bluesky);
    }
    
    .bg-clarity {
        background-color: var(--clarity);
    }
    
    .bg-mango {
        background-color: var(--mango);
    }
    
    .bg-moodswing {
        background-color: var(--moodswing);
    }

    .bg-stone {
        background-color: var(--stone);
    }

    .bg-translucent {
        background-color: var(--translucent);
    }


    
    
    `, "",{"version":3,"sources":["webpack://./src/styles/colors.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,qBAAqB;IACrB,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,wBAAwB;IACxB;;;IAGA,WAAW;IACX;QACI,wBAAwB;IAC5B;;IAEA;QACI,qBAAqB;IACzB;;IAEA;QACI,qBAAqB;IACzB;;IAEA;QACI,mBAAmB;IACvB;;IAEA;QACI,uBAAuB;IAC3B;;IAEA;QACI,mBAAmB;IACvB;;IAEA;QACI,yBAAyB;IAC7B;;IAEA,sBAAsB;IACtB;QACI,mCAAmC;IACvC;;IAEA;QACI,gCAAgC;IACpC;;IAEA;QACI,gCAAgC;IACpC;;IAEA;QACI,8BAA8B;IAClC;;IAEA;QACI,kCAAkC;IACtC;;IAEA;QACI,8BAA8B;IAClC;;IAEA;QACI,oCAAoC;IACxC","sourcesContent":[":root {\n    /* CSS HEX */\n    --stormfront: #3d5a80;\n    --bluesky: #614f60;\n    --clarity:  #ffffff;\n    --mango: #ee6c4d;\n    --moodswing: #000;\n    --stone: #e9e3e3;\n    --translucent: #ffffff4a;\n    }\n    \n    \n    /* COLORS */\n    .stormfront {\n        color: var(--stormfront);\n    }\n    \n    .bluesky {\n        color: var(--bluesky);\n    }\n    \n    .clarity {\n        color: var(--clarity);\n    }\n    \n    .mango {\n        color: var(--mango);\n    }\n    \n    .moodswing {\n        color: var(--moodswing);\n    }\n\n    .stone {\n        color: var(--stone);\n    }\n\n    .translucent {\n        color: var(--translucent);\n    }\n\n    /* BACKGROUND COLORS */\n    .bg-stormfront {\n        background-color: var(--stormfront);\n    }\n    \n    .bg-bluesky {\n        background-color: var(--bluesky);\n    }\n    \n    .bg-clarity {\n        background-color: var(--clarity);\n    }\n    \n    .bg-mango {\n        background-color: var(--mango);\n    }\n    \n    .bg-moodswing {\n        background-color: var(--moodswing);\n    }\n\n    .bg-stone {\n        background-color: var(--stone);\n    }\n\n    .bg-translucent {\n        background-color: var(--translucent);\n    }\n\n\n    \n    \n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
