import React, { useState, useEffect } from "react";

import getContent from '../components/getContent.js';
import ProblemContainer from "../components/ProblemContainer.js";
import ProjectHero from "../components/ProjectHero.js";
import ProjectSetup from "../components/ProjectSetup.js";
import Block from "../components/Block.js";
import NextBack from "../components/NextBack.js";
import Image from "../components/Image.js";
import InsightsBlock from "../components/InsightsBlock.js";
import DetailLayout from "../components/Detaillayout.js";
import SectionTitle from "../components/SectionTitle.js";



const RightClickMenu = () => {
  return (
    <DetailLayout>
       <div className="body-container">
       <ProjectHero
       client="GE Healthcare"
        project="Redefining a common tool and improving productivity for radiologists"
        summary="While at GE, I worked with Radiologists and other stakeholders to solve customer pain points, design and user test a right click menu that would allow radiologists to quickly access tools and functions."
        image="https://raw.githubusercontent.com/lparkos/2020-website/main/rcm/hero-rcm.png"
        />

      <SectionTitle title="Project Snapshot" />


        <ProjectSetup
        solution="Learn commonly used tools that radiologists use and optimize the right click menu to adapt to user needs. "
        outcome="Prove design success and create a resuable pattern that any application can use in our component library. "
        problem="Users are frusterated by a cluttered, growing right click menu with no organizational structure. "
        />

        <SectionTitle title="Process" />

        <Block subhead="Research Approach" subtext="Commencing my research, I devised a card sorting exercise designed to capture insights remotely from a diverse participant pool. Leveraging the Miro software, I tasked participants with grouping tools and functions to understand how users naturally categorize them." />
        <InsightsBlock 
          insights1="1. How do users categorize tools?"
          insights2="2. What are the most frequented tools?"
          insights3="3. How often do users utilize the menu?"
          insights4="4. How can we effectively teach users hotkey commands?"
        />

        <Image image="https://raw.githubusercontent.com/lparkos/2020-website/main/rcm/chart-rcm.png" />
       
        <Block subhead="Data Analysis and Collaboration" subtext="Post-exercise, I collaborated with a data scientist to analyze the collected data. Our collaborative efforts resulted in the creation of dendrograms, visually representing different information architecture structures. From these structures, three potential architectures were selected for further design exploration." />
        
        <Image image="https://raw.githubusercontent.com/lparkos/2020-website/main/rcm/research-bubble-rcm.png" />
        <Block subhead="Tool Usage Analysis" subtext="In addition to building a new archetype, we also analyzed tool usage, so that we could learn what tools users were using the most and how to prioritize them based on use. " />

        <Block subhead="Design Research & Inspiration" subtext="Transitioning to the design phase, I curated a design board showcasing menus from various applications to draw inspiration and share ideas with stakeholders."/>
        <Image image="https://raw.githubusercontent.com/lparkos/2020-website/main/rcm/rcm-board.png" />

        <Block subhead="Prototyping and Testing Iterations" subtext="Building on the gathered insights, I incorporated each architecture into three new interaction patterns. The subsequent step involved creating clickable prototypes using an open-source library, offering users a realistic experience. Testing focused on evaluating the ease of finding tools within specific categories."/>

        <Image image="https://raw.githubusercontent.com/lparkos/2020-website/main/rcm/demo-1-rcm.png" />

        <Block subhead="Proposed Design Iteration" subtext="Following user testing, a design recommendation emerged, and a versatile design pattern was developed to accommodate various use cases."/>
        <Image image="https://raw.githubusercontent.com/lparkos/2020-website/main/rcm/demo-2-rcm.png" />

        <Block subhead="Responsive Design Implementation" subtext="Considering the diverse monitor sizes our applications cater to, we implemented a responsive design pattern. A rule set was established to dynamically collapse tool blocks based on browser width, ensuring an optimal user experience across different devices."/>
        <Image image="https://raw.githubusercontent.com/lparkos/2020-website/main/rcm/demo-3-rcm.png" />

      </div>
    </DetailLayout>
  )

}

export default RightClickMenu;
