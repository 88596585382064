import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { login } from './auth.js'; // import the login function from auth.js

function LoginForm({ onLoginSuccess }) {
  const [enteredPassword, setEnteredPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from; // replace '/default-route' with the route you want to redirect to by default

  const handleSubmit = (event) => {
    event.preventDefault();
    const isUserAuthenticated = login(enteredPassword);
    if (isUserAuthenticated) {
      setEnteredPassword('');
      setError(null);
      navigate(from); // redirect the user to the page they were on before the login screen
      onLoginSuccess(); // call the onLoginSuccess callback
    } else {
      setError('That password doesn\'t work.');
    }
  };

  return (
    <form className="d-block" onSubmit={handleSubmit}>
      <input className='col-8'
        type="password"
        value={enteredPassword}
        onChange={(e) => setEnteredPassword(e.target.value)}
        required
      />
      <button className="btn btn-dark non-serif col-4" type="submit">Enter</button>
      {error && <p className='text-white non-serif my-4'>{error}</p>} {/* display the error message if there is an error */}
    </form>
  );
};

export default LoginForm;