import React from 'react';
import { useLocation } from 'react-router-dom';

const BackToTopButton = () => {
  const { pathname } = useLocation();

  // Don't show the button on the homepage
  if (pathname === '/') {
    return null;
  }

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div onClick={handleClick} style={{ position: 'fixed', bottom: '20px', right: '20px', cursor: 'pointer', zIndex: '10' }}>
      {/* Replace this with your SVG */}
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm4.904 7.404l-4-4a.5.5 0 0 0-.707 0l-4 4a.5.5 0 0 0 .707.707L7.5 4.707V11.5a.5.5 0 0 0 1 0V4.707l2.646 2.647a.5.5 0 0 0 .708-.707z"/>
      </svg>
    </div>
  );
};

export default BackToTopButton;