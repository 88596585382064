import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';

import { Container } from 'react-bootstrap';
import CopyContact from './CopyContact';

function Footer() {
  const location = useLocation();

  if (location.pathname === '/') {
    return null;
  }
  return (
    <div className="container">
       <hr></hr>
        <div class="body-container mx-lg-5 px-lg-5 py-2 row">
          <div class="col-6 align-self-start">
            <CopyContact />
          </div>
          <div class="col-6 align-self-end">
            <p>© 2024 Lauren Parkos</p>
          </div>
        </div>
    </div> 
  )
}


export default Footer;
