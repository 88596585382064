import React, { useState, useEffect } from "react";
import ProjectSetup from "../components/ProjectSetup.js";
import Block from "../components/Block.js";
import SectionTitle from "../components/SectionTitle.js";
import Image from "../components/Image.js";
import DetailLayout from "../components/Detaillayout";
import topicmap from "../images/assets/tas/topicmap.png";
import prototype from "../images/assets/tas/prototype-testing.png";
import miroboard from "../images/assets/tas/miro-board.png";
import problemstatement from "../images/assets/tas/problem-statement.png";
import backstage from "../images/assets/tas/backstage.png";
import output from "../images/assets/tas/output.png";
import ProjectHero from "../components/ProjectHero.js";


const DevPortal = () => {
    return (
      <DetailLayout>
        <div className="body-container">
        
        <ProjectHero
        client="VMWare Tanzu Application Service"
        project="Enhancing the visibility of app information for Developers"
        summary={["VVMware's Tanzu Application Service simplifies the process for application developers to expedite code deployment to production effortlessly. Although highly favored by users, it faced challenges amidst the surge of Kubernetes and competing products. In response, the business sought to demonstrate innovation and collaboration with its Kubernetes counterpart.", <br></br>, <br></br>, "Our task was twofold – not only to deliver a solution but also to substantiate the need with valid customer pain points. Our approach involved constructing a compelling use case around the business initiative."]}
        timeline="2 months"
        />
        
        <SectionTitle title="Project Snapshot" />

        <ProjectSetup
          problem="Discover and solve developer pain points around code discoverability."
          solution="A new plugin that allows developers to quickly and easily find information about their application, who owns it, who's working on it, and information on resources"
          outcome="The business was struggling to show innovation and collaboration with its kubernetes counterpart, our efforts helped to show that we were working together to solve customer problems and innovate on our platform."
          />

        <SectionTitle title="Process" />
        <Block subhead="Identifying Customer Needs" subtext="Despite clear business goals, a crucial aspect was missing – a deep understanding of customer needs and profiles. The absence of pertinent information, including problems, persona details, and relevant data, posed a challenge in formulating an effective solution. I created a topic map. This visual aid played a pivotal role during interviews, guiding discussions and helping me assimilate diverse perspectives."  />

        <Image image={topicmap} />
      
        <Block subhead="Strategic Research Approach" subtext="Collaborating with product managers and engineers, we devised a strategy to bridge the information gap. Our plan involved conducting interviews with a diverse range of customers to discern their preferences, pain points, and expectations."  />

        <Image image={prototype} />

        <Block subhead="Summarizing Insights" subtext="Following extensive research and prototype testing, I diligently distilled key insights derived from our conversations."  />

        <Block subtext="Our primary target audience, the Application Developers, exhibited a keen interest in discoverability, with a hierarchy of priorities: discoverability, application health, and logging information."  />

        <Block subtext="To effectively communicate these findings, I adopted a multifaceted approach. I presented the information while recording the session and then shared both the recording and visual representations across various relevant Slack groups and channels."  />
        <Image image={miroboard} />

        <Block subhead="Problem Definition" subtext="Our research shed light on several pain points experienced by application developers in their daily tasks:"  />

        <Block subtext="1. Information Retrieval Challenges: Developers often struggled to locate relevant information about an app, leading to delays and inefficiencies, especially for those new to a project."  />
        <Block subtext="2. Health Monitoring Concerns: Developers expressed a desire for a streamlined way to ensure the health of their applications, wanting to detect and address errors promptly after code updates."  />
        <Block subtext="3. Documentation and Onboarding Gaps: Feedback revealed that our application lacked comprehensive documentation and onboarding materials, hindering the onboarding process for new team members."  />

        <Image image={problemstatement} />
            
        <Block subhead="Designing Inline with Engineering" subtext="With an emphasis on speed, the team opted for rapid prototyping. I took charge of building a hi-fidelity prototype using the same technology stack as the final product. Leveraging Backstage's platform and design system facilitated seamless creation of components and widgets. The initial prototype was developed using the open-source Backstage software, node.js, and React."  />

        <Image image={backstage} />

        <Block subhead="Visual Design and Brand Alignment" subtext="Embarking on visual design, I utilized an existing architecture as a foundation for presenting information. The Clarity Design System played a pivotal role in ensuring alignment with our brand standards, providing a cohesive and visually consistent user experience." />

        <Image image={output} />
      
      </div>
    </DetailLayout>
 
    )
  }


export default DevPortal;



