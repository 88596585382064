import React, { useState, useEffect } from "react";

import getContent from '../components/getContent.js';
import ProblemContainer from "../components/ProblemContainer.js";
import ProjectHero from "../components/ProjectHero.js";
import ProjectSetup from "../components/ProjectSetup.js";
import Block from "../components/Block.js";
import NextBack from "../components/NextBack.js";
import Image from "../components/Image.js";
import InsightsBlock from "../components/InsightsBlock.js";
import DetailLayout from "../components/Detaillayout.js";


const Comcast = () => {

  return (
    <DetailLayout>
      <div className="body-container">
        <ProjectHero
        project="Comcast Installation"
        summary="I developed and maintained software systems for Comcast flagship store installations, projects included writing tools to automate content swaps and light show configurations. "
        image="https://github.com/lparkos/2020-website/blob/main/comcast/stack_1.png?raw=true"
        />

        <Image image="https://raw.githubusercontent.com/lparkos/2020-website/main/comcast/stack_2.png" />
        <Image image="https://raw.githubusercontent.com/lparkos/2020-website/main/comcast/stack_3.png" />
        
      </div>
    </DetailLayout>
  )
}

export default Comcast;


  