import React, { useState, useEffect } from "react";
import { Container } from 'react-bootstrap';
import Footer from './components/Footer';
import Navibar from './components/Navibar';
import logo from './images/lp-logo.svg';



import Menu from './components/Menu';
import AppRouter from './AppRouter';
import ProjectNavigation from "./components/ProjectNavigation";


function App() {

  return (
    <>
    <div className="">
      <AppRouter />
    </div>
    </>
  );
}


export default App;