import React, { useState, useEffect } from "react";

import ProblemContainer from "../components/ProblemContainer.js";
import ProjectHero from "../components/ProjectHero.js";
import ProjectSetup from "../components/ProjectSetup.js";
import Block from "../components/Block.js";
// import NextBack from "../components/NextBack.js";
import Image from "../components/Image.js";
import InsightsBlock from "../components/InsightsBlock.js";
import DetailLayout from "../components/Detaillayout.js";
import cat from '../images/cat.jpeg';

import hero from "../images/assets/cardioportal/hero.jpg";
import problem from "../images/assets/cardioportal/problem.png";
import iteration from "../images/assets/cardioportal/Iterations.png";
import SectionTitle from "../components/SectionTitle.js";

import people from "../images/assets/cardioportal/people.png";
import designsys from "../images/assets/cardioportal/designsys.png";
import designtech from "../images/assets/cardioportal/cp-research-charts-2.png";
import kickoff from "../images/assets/cardioportal/kickoff.png";
import success from "../images/assets/cardioportal/success.png";
import persona from "../images/assets/cardioportal/personal.png";
import before from "../images/assets/cardioportal/before.png";
import after from "../images/assets/cardioportal/after.png";
import visual1 from "../images/assets/cardioportal/visual-design1.jpg";
import resultsbefore from "../images/assets/cardioportal/results-before.png";
import resultsafter from "../images/assets/cardioportal/results-after.png";
import cardioreal from "../images/assets/cardioportal/cardio-real.png";



const Cardio = () => {
    return (
      <DetailLayout heroImage={cat}>
        <div className="body-container">
          <ProjectHero
          client="GE Healthcare"
          project="Empowering Cardiologists with enhanced patient data visualization"
          summary="During my tenure at GE, I collaborated with Cardiologists and various stakeholders to understand the unique needs of clinicians and the challenges they faced. We embarked on a journey to transform the way they interact with patient data."
          company="GE Healthcare"
          timeline="4 Months"
          />
  
        <SectionTitle title="Project Snapshot" />

          <ProjectSetup
          problem="Patient data is scattered accross many platforms and is difficult to get an overview of a patients cardiac history."
          solution="Enhance design usability and scaleability proven through the use of an MVP and user gathered insights."
          outcome="Create a dashboard that aggregates patient data all in one place."
          />

          <Image image={hero} />

          <br></br>
          <br></br>


          <SectionTitle title="Process" />
  
          <Block subhead="Unveiling the Potential" 
          subtext="In our commitment to user-centricity, we began by identifying key user groups and personas. This laid the foundation for a deep dive into past designs, fostering collaboration with stakeholders to glean insights from prior research. Through engaging goal workshops, our team meticulously outlined project outcomes, pinpointing four crucial areas tailored to user needs:"
          />
  
          <InsightsBlock 
            insights1="1. Validate technical feasibility through a high-fidelity Proof of Concept (POC)"
            insights2="2. Optimize the chart viewing experience for clinicians"
            insights3="3. Enhance informatics to clearly present the patient's cardiac history"
            insights4="4. Conduct user testing with clinicians to collect valuable feedback"
          />
          <Image image={persona} />

          <Block subhead="Problem Research through User Interviews" 
          subtext="In response to customer feedback highlighting shortcomings in previous prototypes, we embarked on a mission to align with real-world scenarios. By identifying critical information for cardiologists and centralizing the patient's data, we identified 3 main usability pain points:"
          />
           <InsightsBlock 
            insights1="1. Users struggled to grasp the context of data sets, hindering their ability to comprehend the intricacies of the patient's cardiac history."
            insights2="2. The trend chart presented a significant challenge, lacking clarity and proving to be an obstacle in the user's efforts to interpret and understand critical information."
            insights3="3. Vital patient events were obscured, leaving users grappling with an inability to access clear and concise displays, thereby impeding their comprehensive understanding of crucial medical events."
          />
          <Image image={problem} />

          <Block subhead="Wireframing" subtext="With objectives in place, I embarked on the design phase, exploring layouts that prioritized the patient's data for a sequential reading experience. Early wireframe explorations were conducted to refine the visual structure." />

          <Image image={visual1} />

          <Block subhead="Incorporating the Design System" subtext="Leveraging our Edison Design System's color palette, I collaborated with an iconographer to develop illustrations and compliant abbreviations, enhancing the scannability of data points. Purposeful use of color facilitated the indication of exam severity in each data point. Interaction elements were carefully crafted to accommodate various chart interactions, including consideration for dark mode in environments with low light conditions." />
          <Image image={designsys} />
  
          <Block subhead="Hi Fidelity Design & Prototyping" subtext="Through iterative design rounds, a comprehensive plan was formulated and translated into a Figma prototype. Multiple rounds of user testing were conducted to address pain points identified in the initial design, ensuring a refined and user-friendly solution."/>
          <Image image={iteration} />

          <Block subhead="Measuring Results" subtext="Success was measured through A/B tests conducted early in the process, comparing the original design with the reworked version. Analyzing consistent metrics, the results demonstrated success, leading to additional partnerships, increased funding, and heightened stakeholder interest."/>
          <Image image={resultsafter} />
          
          <Block subhead="Product Launch" subtext="Following a rigorous process of obtaining necessary approvals and FDA clearance, our product, CardioVisio, was successfully launched in 2023. It was introduced as a key component of the GE Healthcare Cardio suite. We are pleased to announce that CardioVisio is now widely available for general use."/>

          <Image image={cardioreal} />

        </div>
  
      </DetailLayout>
    )
}

export default Cardio;



