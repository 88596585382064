import React, { useState, useEffect } from "react";

import getContent from '../components/getContent.js';
import ProblemContainer from "../components/ProblemContainer.js";
import ProjectHero from "../components/ProjectHero.js";
import ProjectSetup from "../components/ProjectSetup.js";
import Block from "../components/Block.js";
import NextBack from "../components/NextBack.js";
import Image from "../components/Image.js";
import InsightsBlock from "../components/InsightsBlock.js";
import DetailLayout from "../components/Detaillayout.js";



const Goldman = () => {
  return (
    <DetailLayout>
        <div className="body-container">
        <ProjectHero
        client="Elephant / Client: Goldman Sachs"
        project="Goldman Sachs Retirement Calculator"
        summary="I developed a series of data visualizations for a retirement product calculator. "
        image="https://raw.githubusercontent.com/lparkos/2020-website/main/goldman/hd-1.png"
        />

        <Block subhead="Building the visualization" subtext="Goldman Sachs Retirement Calculator"  />
          <Image image="https://raw.githubusercontent.com/lparkos/2020-website/main/goldman/graph-3-looping-delay.gif" />
          <Image image="https://raw.githubusercontent.com/lparkos/2020-website/main/goldman/graph-4-looping-delay.gif"/>
        </div>
    </DetailLayout>
  )
}


export default Goldman;
