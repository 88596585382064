import React, { useState, useEffect } from "react";

import getContent from '../components/getContent.js';
import ProblemContainer from "../components/ProblemContainer.js";
import ProjectHero from "../components/ProjectHero.js";
import ProjectSetup from "../components/ProjectSetup.js";
import Block from "../components/Block.js";
import NextBack from "../components/NextBack.js";
import Image from "../components/Image.js";
import InsightsBlock from "../components/InsightsBlock.js";
import DetailLayout from "../components/Detaillayout.js";
import SectionTitle from "../components/SectionTitle.js";


import hero from "../images/assets/mcdonalds/hero.jpg";
import baganimation from "../images/assets/mcdonalds/Add_To_Bag_Animation-2.gif";
import productbuildin from "../images/assets/mcdonalds/Product_Wall_Build_In.gif";
import mainscreen from "../images/assets/mcdonalds/OrderingFlow-high.gif";
import testing from "../images/assets/mcdonalds/testing.png";
import localization from "../images/assets/mcdonalds/localization.png";







const MCD = () => {
  return (
    <DetailLayout>
      <div className="body-container">
        <ProjectHero
        client="Method / Client: McDonald's"
        project="Building a Kiosk experience for McDonald's customers"
        summary="I worked on several projects within a design/UX team to build a Kiosk experience for McDonald's customers. "
        />

<Image image={hero} />


          <ProjectSetup
          problem="How can we show customers the value of a kiosk experience?"
          solution="A real-time prototype that can be used to test with customers and stakeholders."
          outcome="Closes the gap between the customer and the kiosk experience, and provides a more seamless experience for the customer."
          />

        <Block subhead="Main projects" 
        subtext="I played a role on several projects while working on McDonald's ordering initiatives, including prototyping in React, facilitating testing sessions while putting up and taking down kiosks, writing reusable animations, and building in localization scripts to accomodate different markets. "
        />


        <InsightsBlock 
          insights1="Adding to Cart Animation"
          image1={baganimation}
          insights2="Main Product Wall Build In Animation"
          image2={productbuildin}
        />

        <Block 
          subhead="Prototyping" 
          subtext="The main prototype was built using a React/Redux view library, with reusable components. To maintain user flows, I worked along side a UX Design team to create proof of concepts and build flows for different customer scenarios and use cases. "
        />
        <Block subhead="Localization" subtext="Designed technical plans and strategies for how copy and content existed inside prototypes based on language and country. Worked on a technical and design teamS to construct and localize plans for UK, France, Australia, and the US. Contributed largely to optimizing data structures for various markets with our integrated CMS (Contentful), and designed functions that toggled language and content files. Worked with UX/design teams to rework experience deltas between markets." />
        

        <Image image={localization} />
        <SectionTitle title="Image Credit: Adam Augustyn/Lead Visual Designer" />

        <Block subhead="Facilitating user testing" subtext="Played an active role in designing test experiences and designed moderating instructions for research teams." />

        <Image image={testing} />
        <Block subhead="Creating reusable animations" subtext="Created a UI Motion Pattern library, and worked with our client’s production developers to implement systematized patterns into live builds. Provided production developers with custom libraries and animation classes to use inside of their own production builds." />
        <Image image={mainscreen} />
        <SectionTitle title="Animation Credit: Dave Toro (Design Partner)" />


        <Block subhead="Ordering flow" subtext="Example of one of the primary meal loops, to view enter password mcd" />
        <div className="pt-6 pb-6">
          <iframe src="https://player.vimeo.com/video/241453313" width="100%" height="450px" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div>




      </div>
    </DetailLayout>
  )
}

export default MCD;


