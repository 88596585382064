import React, {useState} from 'react';

const CopyContact = () => {
  const [isCopied, setIsCopied] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    navigator.clipboard.writeText('.parkos@gmail.com');
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000); // hide tooltip after 2 seconds
  };

  return (
        <div class="d-lg-flex">
            <p className="text-body-secondary non-serif pe-1">Want to get in touch?</p> 
            <a className="light" data-bs-toggle="tooltip" data-bs-title="Click to Copy" onClick={handleClick}
            >lauren.parkos@gmail.com</a>
            {isCopied && <p className="text-body-secondary non-serif light">Copied to clipboard!</p>}
        </div>
  )
}


export default CopyContact;
