import React from "react";
import Header from './Header';
import { Container } from "react-bootstrap";
import HoverLink from './HoverLink';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import leftArrow from '../images/arrow-left.svg';
import rightArrow from '../images/arrow-right.svg';
import '../styles/app.css';



const ProjectNavigation = (props) => {
  const location = useLocation();
  return (
      <div className="position-absolute top-0 start-100 translate-middle">
        {location.pathname === '/' ? null : <HoverLink destination="/" title="X"/>}
        <div className="container columns is-flex pt-6 pb-6 is-centered">
            <div className="column is-three-fifths pb-6 ">
                <div className="columns">
                <div className="column is-half link-container is-uppercase">
                    <div className="is-size-5 has-text-weight-bold is-flex">
                        <img className="pr-3 image is-32x32" src={leftArrow} />
                        <Link to={props.backurl}>Last Project</Link>
                    </div>
                </div>
                <div className="column is-half link-container is-uppercase is-justify-content-flex-end">
                    <div className="is-size-5 has-text-weight-bold is-flex">
                    <Link to={props.nexturl}>Next Project</Link>
                    <img className="pl-3 image is-32x32" src={rightArrow} />
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
  );
};

export default ProjectNavigation;