import React, { useState, useEffect } from "react";

import getContent from '../components/getContent.js';
import ProblemContainer from "../components/ProblemContainer.js";
import ProjectHero from "../components/ProjectHero.js";
import ProjectSetup from "../components/ProjectSetup.js";
import Block from "../components/Block.js";
import NextBack from "../components/NextBack.js";
import Image from "../components/Image.js";
import InsightsBlock from "../components/InsightsBlock.js";
import DetailLayout from "../components/Detaillayout.js";
import SectionTitle from "../components/SectionTitle.js";


import accelerate from "../images/assets/motion/accelerate.gif";
import decelerate from "../images/assets/motion/decelerate.gif";
import standard from "../images/assets/motion/standard.gif";
import structure from "../images/assets/motion/structure.png";
import userstory from "../images/assets/motion/user-story-banner.png";
import easings from "../images/assets/motion/easings2.gif";
import tool from "../images/assets/motion/editing-tool.gif";
import modal from "../images/assets/motion/modal.gif";
import animatedcomponent from "../images/assets/motion/animated-component.png";
import inline from "../images/assets/motion/writing-code.gif";

import taganimation from "../images/assets/motion/tag-animation.gif";










const MotionLib = () => {
    return (
    <DetailLayout>

       <div className="body-container">
       <ProjectHero
        client="GE Healthcare"
        project="Enhancing the user experience with motion design and animation"
        summary="While at GE, I worked with a team of designers and developers to create a set of animations that could be used in our design system. "
        />

        <SectionTitle title="Project Snapshot" />

        <ProjectSetup
        solution="Incorporate animations into design system components and create alternative methods of recreating animations for consumers that are not using web components."
        outcome="Enhance the user experience for our users by providing animations that don't distract or interupt but gracefully guide users through their workflows. "
        timeline="1 Year"
        problem="Our applications don't offer any animation design guidance or principals, and also don't offer any technical solutions on how to implement. Our users are left with a choppy and harsh experience. "
        />

        <SectionTitle title="Process" />

        <Block subhead="Approach" subtext="I started by first designing a set of guidelines and starting concepts that keep our users at the forefront of every animation. By establishing these principals we can be sure that we would always be animating with intent and not distracting the user or adding any bloating code. "  />

        <Image image={structure} />

        <Image image={userstory} />

        <Block subhead="Building the basics" subtext="I then built out a set of basic animations that I knew we would need in our most basic form. Additionally, I built a a set of easings that would help us establish entering and exiting principals and a variable timing structure to keep everything consistant. "/>
        
        <SectionTitle title="Easings" />

        {/* Easings */}
        <InsightsBlock 
            insights1="Accelerate" image1={accelerate}
            insights2="Decelerate" image2={decelerate}
            insights3="Standard" image3={standard}
          />
    

        <Block subhead="Design Collaboration" subtext="While further building a basic animation list, I designed a tool to help perfect each animation with another designer. The tool gave the designer direct editing capabilities on the transition class that I created to handle the animations. " />

        <Image image={tool} />
        
        <Block subhead="Animations as mixins" subtext="I worked on encorporating our animations as css transitions into our component code. The easiest way to do this was to create reusable SASS mixins that could be used throughout the component with varying degrees of speed and animation intensity. These mixins were only transitions and didn't alter the state of the component, which the component handled 
        independently. "/>

        <Image image={inline}/>

        <Block subhead="Delivering Animated Components" subtext="Consumers of the design system could elect to use an animated component without any configuration work. Components that use this setting will adhere by the animation guidelines and work out of the box. "/>

        <Image image={animatedcomponent} />

        <SectionTitle title="Examples" />
        <Image image={modal} />
        <Image image={taganimation} />
   

        </div>
    </DetailLayout>
    )
}


export default MotionLib;
