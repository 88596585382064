import React, {useEffect, useState} from 'react';
import closer from '../images/close.svg';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';


const MenuToggler = () => {
 const location = useLocation();

  return (
    <div>
      <p className="h5">
        {location.pathname !== '/' ? 
        <Link to="/"><img className="closer" src={closer} alt="Close" /></Link>
        : 'Projects'}
      </p>
    </div>
  )
}


export default MenuToggler;