import React, {useEffect, useState} from 'react';

class Image extends React.Component {
  render() {
    return <div class="container-fluid">
              <div className="columns is-centered">
                <figure className="column is-four-fifths image has-text-centered">
                    <img className="container is-centered"
                    effect="blur"
                    src={this.props.image} />
                </figure>
              </div>
    </div>
  }
}

export default Image;
