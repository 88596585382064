import React, {useEffect, useState} from 'react';

class ProjectSetup extends React.Component {
  render() {
    return  <div className="container d-flex mb-5">
        <div className="row align-items-start">
            <div className="col">
                <p className="h5 border-bottom bold">Problem</p>
                <p className="fs-5 pt-2">{this.props.problem}</p>
            </div>

            <div className="col">
                <div className="column is-half">
                    <p className="h5 border-bottom bold">Solution</p>
                    <p className="fs-5 pt-2">{this.props.solution}</p>
                </div>
            </div>
 
            <div className="col">
                <p className="h5 border-bottom bold">Outcome</p>
                <p className="fs-5 pt-2">{this.props.outcome}</p>
            </div>
        </div>

    </div>
  }
}

export default ProjectSetup;