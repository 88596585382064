import React, { useState, useEffect } from "react";
import Header from './Header';
import { Container } from "react-bootstrap";
import Menu from './Menu.js';

import HoverLink from './HoverLink';
import { useLocation } from 'react-router-dom';
import '../styles/app.css';


const DetailLayout = ({children}) => {
  return (

      <div className="ms-lg-5 scroll-container">
          {children}
      </div>

  );
};

export default DetailLayout;