// auth.js

export function login(enteredPassword) {
  const storedPassword = process.env.REACT_APP_PASSWORD;

  console.log('Entered password:', enteredPassword); // Debug: log the entered password
  console.log('Stored password:', storedPassword); // Debug: log the stored password

  if (enteredPassword === storedPassword) {
    localStorage.setItem('isAuthenticated', 'true');
    return true;
  } else {
    localStorage.removeItem('isAuthenticated');
    return false;
  }
}

export function isAuthenticated() {
  return localStorage.getItem('isAuthenticated') === 'true';
}