import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';

class Navibar extends React.Component {
  render() {
    return (
      <Navbar expand="lg" className="hide-on-large">
        <Container fluid>
          <Navbar.Brand href="/" className="ms-4 fs-6 fw-lighter">Work of Lauren Parkos</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav">
            <Nav className="mr-auto ms-4 py-3">
              <Nav.Link className="py-1" href="/cardio">Visualizing Cardiology Data</Nav.Link>
              <Nav.Link className="py-1" href="/devportal">Tools for Developer Productivity</Nav.Link>
              <Nav.Link className="py-1" href="/ai">VMware AI Tooling</Nav.Link>
              <Nav.Link className="py-1" href="/mcd">McDonalds Kiosk Prototyping & Motion Design</Nav.Link>
              <Nav.Link className="py-1" href="/rcm">Redefining Contextual Menus</Nav.Link>
              <Nav.Link className="py-1" href="/voice">Voice Powered Modality Research</Nav.Link>
              <Nav.Link className="py-1" href="/motion">Motion Engineering for Edison Design System</Nav.Link>
              <Nav.Link className="py-1" href="/scroll">Z-Index Scrolling for Radiology Patent</Nav.Link>
              <Nav.Link className="py-1" href="/comcast">Maintaing Colorful Screens with Comcast</Nav.Link>
              <Nav.Link className="py-1" href="/goldman">Charting Consumer Savings with Goldman Sachs</Nav.Link>

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default Navibar;