// PrivateRouteWrapper.js
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import LoginForm from './LoginForm'; // import your LoginForm component
import { login } from './auth'; // adjust the path as needed
import { Link } from 'react-router-dom';
import Footer from './Footer';



function isAuthenticated() {
  const isAuthenticated = localStorage.getItem('isAuthenticated');
  return isAuthenticated === 'true';
}

function PrivateRouteWrapper({ element }) {
  const [showModal, setShowModal] = useState(!isAuthenticated());
  const navigate = useNavigate();
  const location = useLocation();

  const handleLoginSuccess = () => {
    setShowModal(false);
  };

  const handleLogin = async (password) => {
    const isUserAuthenticated = await login(password);
    if (isUserAuthenticated) {
      localStorage.setItem('isAuthenticated', 'true');
      setShowModal(false);
      navigate(location.state?.from); // navigate to the previous page or to the default page
    }
  };

  useEffect(() => {
    if (!isAuthenticated()) {
      setShowModal(true);
    }
  }, [location]); // listen for changes in the location object

  return (

    <>
      {isAuthenticated ? element : null}
      <Modal className="text-center" show={showModal} onHide={() => isAuthenticated() && setShowModal(false)}  backdrop="static" keyboard={false}>
        <p className="non-serif text-white">To view this case study, contact me at lauren.parkos@gmail.com</p>
        <LoginForm onLoginSuccess={handleLoginSuccess} />
        <Link to="/" className="btn btn-dark non-serif my-2 text-white">Back</Link>
      </Modal>
    </>
  );
}

export default PrivateRouteWrapper;