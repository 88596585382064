import React, {useEffect, useState} from 'react';

class InsightsBlock extends React.Component {
    render() {
        return (
          <div className="pt-3 pb-1 mb-4 is-centered border-top border-bottom">
            <div className="container text-center">
              <div className="row align-items-start">
                {this.props.insights1 && (
                  <div className="col col-container">
                    <div className="has-background-light has-text-centered p-2">
                      <p className="fs-5">{this.props.insights1}</p>
                      <img className="img-fluid" src={this.props.image1} />
                    </div>
                    <span className="deco-circle"></span>
                  </div>
                )}
                {this.props.insights2 && (
                  <div className="col col-container">
                    <div className="has-background-light has-text-centered p-2">
                      <p className="fs-5">{this.props.insights2}</p>
                      <img className="img-fluid" src={this.props.image2} />
                    </div>
                    <span className="deco-circle"></span>
                  </div>
                )}
                {this.props.insights3 && (
                  <div className="col col-container">
                    <div className="has-background-light has-text-centered p-2">
                      <p className="fs-5">{this.props.insights3}</p>
                      <img className="img-fluid" src={this.props.image3} />
                    </div>
                    <span className="deco-circle"></span>
                  </div>
                )}
                {this.props.insights4 && (
                  <div className="col col-container">
                    <div className="has-background-light has-text-centered p-2">
                      <p className="fs-5">{this.props.insights4}</p>
                      <img className="img-fluid" src={this.props.image4} />
                    </div>
                    <span className="deco-circle"></span>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      }
    }

export default InsightsBlock;
