import * as React from 'react'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { motion, AnimatePresence } from "framer-motion" 


const defaultStyles= {
    transition: `scale 400ms ease-in-out`,
  };
  
  const transitionStyles = {
    entering: { scale: .3 },
    entered:  { scale: 1 },
    exiting:  { scale: .3 },
    exited:  { scale: 0 },
  };


const HoverLink = ({imagethumb, title, destination, subclient, menuClass}) => {
    const location = useLocation();
    const isAppRouterPage = location.pathname === '/'; // replace '/approuter' with the path of your approuter page
    const [isShown, setShown] = useState(false);

    const variants = {
      open: { height: isAppRouterPage ? "4rem" : "5rem", transition: { duration: 0.2 } },
      closed: { height: isAppRouterPage ? "1rem" : "2rem", transition: { duration: 0.2 } }
  }

    const toggleImage = () => {
        setShown(current => !current);
      };

    return (
        <motion.div 
            animate={isShown ? "open" : "closed"} 
            variants={variants} 
            className={`display-block pt-2 pb-4 position-relative bg-clarity menu-row ${isShown ? "active" : "normal"}`} onMouseEnter={toggleImage} onMouseLeave={toggleImage}>


              <Link to={destination} className="h5 light" onClick={() => console.log(`Navigating to ${destination}`)}>
                <div>
                  {menuClass === 'menu-large' && 
                    <img className={`thumb shadow rounded-2 ${isShown ? "fadeIn" : "fadeOut"}`} src={imagethumb} /> 
                  }
                </div>
                <span>{title}</span>
              </Link>

            { isShown ? 
            <div>
                <p className="text-body-secondary light">{subclient}</p>
            </div>
            : ""}
        </motion.div>
    );
}

export default HoverLink