import React, { useState, useEffect } from "react";

import getContent from '../components/getContent.js';
import ProblemContainer from "../components/ProblemContainer.js";
import ProjectHero from "../components/ProjectHero.js";
import ProjectSetup from "../components/ProjectSetup.js";
import Block from "../components/Block.js";
import NextBack from "../components/NextBack.js";
import Image from "../components/Image.js";
import InsightsBlock from "../components/InsightsBlock.js";
import DetailLayout from "../components/Detaillayout.js";
import SectionTitle from "../components/SectionTitle.js";



const Scrolling = () => {
  return (
    <DetailLayout>
       <div className="body-container">
        <ProjectHero  
        client="GE Healthcare"
        project="Applying scrolling enhancements to improve radiologist workflows"
        summary="I designed, prototyped, and wrote a patent to create a scrolling concept that allowed users to control scrolling velocity and speed. "
        image="https://raw.githubusercontent.com/lparkos/2020-website/main/scrolling/hero.png"
        />
        
        <SectionTitle title="Project Snapshot" />
        <ProjectSetup
        solution="Understand risk and learn technical limitations from device and scroll interactions. "
        outcome="Prove or disprove scrolling enhancements will increase productivity with users without patient risk through user testing. "
        timeline="6 Months"
        problem="Radiologists spend a lot of time manually scrolling through large image sets at one speed, causing scroll fatigue and user frusteration." 
        />

        <SectionTitle title="Process" />

        {/* Approach */}
        <Block subhead="Approach" subtext="Radiologists spend a lot of time manually scrolling through large image sets at one speed, causing scroll fatigue and user frusteration."/>

        <InsightsBlock 
          insights1="1. Understand user workflow and behaviours"
          insights2="2. Audit scrolling technology used amongst users"
          insights3="3. Explore patterns that aleviate strain"
          insights4="4. Test on users to assess risk and feedback"
        />

        <Block subhead="Job shadow and technology audit" subtext="I set up research trips to visit a partner clinic so that I could shadow radiologists and interview them about their scrolling behavior. I also created a list of different scrolling technologies and workspace setups. " />

        {/* IMAGE OLD CP */}
        <Image image="https://raw.githubusercontent.com/lparkos/2020-website/main/scrolling/mouse-types.png" />

        <Block subtext="After meeting with users, I learned why they wanted a scroll feature and what kinds of devices people were mostly using. I learned that there were several different methods people were using, but mostly users were at a hospital desk with an ordinary scroll mouse."/>

        <Block subhead="Code a similar environment" subtext="To being ideating on my prototypes, I need an environment that I could easily manipulate and allow for quick technical ideation. I first obtained an example data set of 300 images where I then wrote a simple javascript function that rendered them to a webpage and then show/hid each image on a scroll-event. " />
        <Image image="https://raw.githubusercontent.com/lparkos/2020-website/main/scrolling/code.png" />

        <Block subhead="Design Ideation" subtext="After a series of prototypes were made, we began hallway testing to internal teams to collect feedback. A major risk that we learned about was image skipping and the crucial design need to indicate to the doctor when an image has been skipped due to increasing the frame rate. " />

        <div className="pt-6">
          <iframe src="https://player.vimeo.com/video/465964261" width="100%" height="450px" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div>
        <div className="pt-6">
          <iframe src="https://player.vimeo.com/video/465962926" width="100%" height="450px" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div>
        <div className="pt-6">
          <iframe src="https://player.vimeo.com/video/465963175" width="100%" height="450px" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div>

        <Block subhead="Gathering insights" subtext="After rounds of exploration, none of the concepts proved well enough to release to users, however we were able to patent one of our explorations that applied momentum based scroll when scrolling through a z-indexed stack of images. " />

        <Block subhead="Achieving Patent" subtext="After rounds of exploration, none of the concepts proved well enough to release to users, however we were able to patent one of our explorations that applied momentum based scroll when scrolling through a z-indexed stack of images. " />
        <Image image="https://raw.githubusercontent.com/lparkos/2020-website/main/scrolling/diagram.png" />

      </div>
    </DetailLayout>
  )

}

export default Scrolling;




